<template>
  <div class="sub-header-container">
    <header class="header navbar navbar-expand-sm" id="breadCrumbHeader">
      <a
        href="javascript:void(0);"
        id="navToggler"
        class="sidebarCollapse"
        data-placement="bottom"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line></svg
      ></a>

      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  {{ currentRouteName }}
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </header>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  watch: {
    currentRouteName: {
      immediate: true,
      handler() {
        setTimeout(() => {
          if (!$("#breadCrumbHeader").hasClass("expand-header")) {
            //console.log('has')
            document.getElementById("navToggler").click();
          } else {
            // console.log('does not')
          }
        }, 500);
      },
    },
  },
};
</script>
